import styled from 'styled-components'
import { tokens, media, box } from '@tokens'
import { Hero } from '@styles/components/Hero'
import { PageWrapper } from '@styles/components/Wrapper'
export { PageWrapper } from '@styles/components/Wrapper'

export const Archive = styled.section`
  margin: 0 auto;
  
  ${media.greaterThan('m')`
    max-width: ${tokens.get('maxWidth.base')};
  `}

  ${media.greaterThan('xl')`
    max-width: ${tokens.get('maxWidth.wide')};
  `}
`