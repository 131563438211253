import PageTransition from '@transitions/PageTransition'

// NOTE: If we used Typescript we could create an interface for this.

class FadeTransition extends PageTransition {
  constructor({
    exitTransitionDuration = 0.5,
    entryAnimationDuration = 0.75,
    entryTransitionDuration = 1,
    entryDelay = 0.25,
  } = {}) {
    super({
      exitTransitionDuration,
      entryAnimationDuration,
      entryTransitionDuration,
      entryDelay,
    })

    this.base = {
      opacity: 1,
    }
  }

  entry({ exit, node }) {
    TweenMax.fromTo(
      node,
      this.duration.entry.animation,
      {
        opacity: 0,
        ease: Power2.easeInOut,
      },
      this.base
    )
  }
}

export default FadeTransition
