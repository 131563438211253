class PageTransition {
  constructor({
    exitAnimationDuration = 1,
    exitTransitionDuration = 1,
    exitDelay = 0,
    entryAnimationDuration = 1,
    entryTransitionDuration = 1,
    entryDelay = 0.25,
  } = {}) {
    this.duration = {
      exit: {
        animation: exitAnimationDuration,
        transition: exitTransitionDuration,
      },
      entry: {
        animation: entryAnimationDuration,
        transition: entryTransitionDuration,
      },
    }
    this.delay = {
      exit: exitDelay,
      entry: entryDelay,
    }
  }

  revertHeader(duration = 0.2) {
    const headerEl = document.querySelector('.header.in-portal')
    const fixedHeaderEl = document.querySelector('.header.in-portal > .inner')

    if (fixedHeaderEl) {
      document.body.classList.remove('sticky-header')
      TweenMax.to(fixedHeaderEl, duration, {
        height: headerEl.offsetHeight,
        ease: Power2.easeIn,
      })
    }
  }

  exit({ exit, node }) {
    return true
  }

  entry({ exit, node }) {
    return true
  }

  getDuration(direction) {
    return this.duration[direction].transition
  }

  getDelay(direction) {
    return this.delay[direction]
  }
}

export default PageTransition
