import React from 'react'
import PropTypes from 'prop-types'
import Image from '@partials/Image'
import { H3 } from '@styles/Typography'
import * as S from '@styles/components/Teaser'

export const TeaserContent = props => {
  const { date, title, subtitle, summary, image, style } = props
  const aspectRatio = style === 'SmallTeaser' ? 16 / 19 : 8 / 5

  return (
    <>
      <Image source={image} aspectRatio={aspectRatio} />
      {!image && (
        <div className="image-placeholder">
          <div className="inner"></div>
        </div>
      )}
      <div className="content">
        {date && (
          <S.Meta>
            <div className="date">{date}</div>
          </S.Meta>
        )}
        <H3 className="heading">{title}</H3>
        {subtitle && <h4 className="subheading">{subtitle}</h4>}

        <div
          className="summary"
          dangerouslySetInnerHTML={{
            __html: summary,
          }}
        />
      </div>
    </>
  )
}

class Teaser extends React.Component {
  constructor(props) {
    super(props)
    this.summary = this.getSummary()
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    // TODO: Convert to shape
    acf: PropTypes.object,
    date: PropTypes.string,
    path: PropTypes.string,
  }

  getSummary(length = 75) {
    const contentField = this.props.acf.content
      ? this.props.acf.content
      : this.props.content

    if (!contentField) {
      return null
    }

    let content = contentField
      .replace(/(<([^>]+)>)/gi, '')
      .substring(0, length)
      .replace(/[\.!\?,;:\s]*$/, '')

    return `<p>${content}</p>`
  }

  render() {
    const {
      type: postType,
      teaserStyle: style,
      wordpress_id: sid,
      path,
      ...props
    } = this.props
    const summary = this.summary
    const StyledTeaser = S[style]

    return (
      <StyledTeaser {...props} to={path} postType={postType} sid={sid}>
        <TeaserContent style={style} summary={summary} {...props} />
      </StyledTeaser>
    )
  }
}

export default Teaser
