import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Link from '@partials/Link'
import Teaser from '@components/Teaser'
import * as S from '@styles/components/Teaser'
import { Location } from '@reach/router'
import FadeTransition from '@transitions/FadeTransition'

export default class PostList extends Component {
  static propTypes = {
    posts: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.index = {
      post: '/blog',
      project: '/projects',
      service: '/serviecs',
    }

    this.base = this.index[this.props.posts[0].node.type]
    this.transition = new FadeTransition()

    // TODO: Reference blog.js query. We need to implement query directives for this to work properly
    // this.featurePost = true

    // if (this.featurePost && props.posts) {
    //   this.featured = props.posts.shift().node
    // }
  }

  isIndex(path) {
    const pattern = `^\\${this.base}(\/page\/.*)?$`
    const regex = new RegExp(pattern)

    if (path.match(regex)) {
      return true
    }

    return false
  }

  render() {
    const { type, posts, categories, title, useFlyout } = this.props
    let teaserStyle

    switch (type) {
      case 'project':
        teaserStyle = 'SmallTeaser'
        break
      default:
        teaserStyle = 'Teaser'
        break
    }

    return (
      <section className="section">
        {categories && (
          <S.Filter className="categories">
            <ul className="options">
              <li key={0}>
                <Location>
                  {({ location }) => (
                    <Link
                      className={`option ${
                        this.isIndex(location.pathname) ? 'is-active' : ''
                      }`}
                      activeClassName="is-active"
                      to={this.index[posts[0].node.type]}
                      transition={this.transition}
                    >
                      <S.Meta>Recent Posts</S.Meta>
                    </Link>
                  )}
                </Location>
              </li>
              {categories.map(({ node: category }) => {
                if (category.name !== 'Uncategorized') {
                  return (
                    <li key={category.id}>
                      <Link
                        className="option"
                        activeClassName="is-active"
                        to={category.path}
                        transition={this.transition}
                      >
                        <S.Meta>{category.name}</S.Meta>
                      </Link>
                    </li>
                  )
                }
              })}
            </ul>
          </S.Filter>
        )}
        {/* TODO: Reference blog.js query. We need to implement query directives for this to work properly */}
        {/* {this.featured && (
          <Post key={this.featured.id} featured={true} {...this.featured} />
        )} */}
        <S.PostList teaserStyle={teaserStyle}>
          {posts.map(({ node: post }) => {
            switch (post.type) {
              case 'post':
                post.image = post.acf.image
                break
              case 'project':
                post.image = post.acf.featured_image || post.acf.hero_image
                post.subtitle = post.acf.role
                break
            }

            return (
              <Teaser
                key={post.id}
                teaserStyle={teaserStyle}
                {...post}
                useFlyout={useFlyout}
              />
            )
          })}
        </S.PostList>
      </section>
    )
  }
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    content
    path
    type
    wordpress_id
    acf {
      sub_heading
      image {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    date(formatString: "MMMM D, YYYY")
  }
  fragment ProjectListFields on wordpress__wp_project {
    id
    title
    path
    type
    wordpress_id
    acf {
      # date(formatString: "MMMM D, YYYY")
      hero_image {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      featured_images {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      role
      client {
        post_name
        post_title
      }
    }
  }
  fragment ServiceListFields on wordpress__wp_service {
    id
    title
    path
    type
    wordpress_id
    acf {
      # date(formatString: "MMMM D, YYYY")
      content_service {
        __typename
        ...AccordionBlockComponentFragment
        ...BannerComponentFragment
        ...CardsComponentFragment
        ...FeaturedProjectsComponentFragment
        ...FormComponentFragment
        ...ImageComponentFragment
        ...ImageBlocksComponentFragment
        ...LogoGridComponentFragment
        ...ListBlockComponentFragment
        ...SliderComponentFragment
        ...TeamComponentFragment
        ...TestimonialsComponentFragment
        ...TextComponentFragment
        ...TextWithImagesFragment
        ...TilesComponentFragment
      }
      hero {
        sub_heading
      }
    }
  }
`
