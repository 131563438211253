import styled from 'styled-components'
import { spacing, box } from '@tokens'

export const PageWrapper = styled.div`
  ${box()};
  /* NOTE: This seems ...not great */
  padding-top: ${spacing(4)} !important;
`

export default PageWrapper
